<template>
  <div class="confirm-order">
    <TopBar :type="2" :pageTitle="'确认订单'"/>
    <div class="confirm-content">
      <div class="order-info">
        <div class="info">
          <img class="order-img" :src="confirmOrder.image">
          <div class="right">
            <p class="name">{{confirmOrder.name}}</p>
            <p class="price"><span class="unit">￥</span>{{confirmOrder.original_price || confirmOrder.virtual_price}}</p>
          </div>
        </div>
        <p class="info-2">
          <span class="title">商品类型</span>
          <span class="text">{{confirmOrder.type}}</span>
        </p>
        <p class="info-2">
          <span class="title">商品总额</span>
          <span class="text">￥{{confirmOrder.original_price || confirmOrder.virtual_price}}</span>
        </p>
        <p class="actual-payment">实付款<span class="unit">￥</span><span class="price">{{confirmOrder.price}}</span></p>
      </div>
      <div class="pay-type">
        <p class="title">请选择支付方式</p>
        <label class="pay-s weixin" :class="{'active': payType==0}" @click="payType=0">微信</label>
        <label class="pay-s zhifubao" :class="{'active': payType==1}" @click="payType=1" v-if="!getIsWeiXin()">支付宝</label>
      </div>
    </div>
    <button class="sub-btn" @click="payBtn">立即支付</button>
    <div class="zhifu-content" v-html="zhifu"></div>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue';
import { mapMutations } from "vuex";
import Qs from 'qs'
export default {
  name: 'ConfirmOrder',
  data(){
    return {
      payType: 0, //支付方式
      confirmOrder: '',
      agree: 1,
      courseId: '',
      payInfo: '',  //订单信息
      zhifu: '',
      isWeiXin: false
    }
  },
  components: {
    TopBar
  },
  mounted(){
    this.getConfirmOrder();//获取信息
  },
  methods: {
    ...mapMutations(['ShowTipModal']), //tip弹窗
    //获取信息
    getConfirmOrder(){
      this.courseId = this.$route.params.id;
      let getUrl = '';
      let data = {};

      if(this.courseId.includes('series')){
        getUrl = '/v1/subject/confirmOrder';
        data.subject_id = this.courseId.split('_')[1];
      }else{
        getUrl = '/v1/course/confirmOrder';
        data.course_id = this.courseId;
      }

      this.$axios.post(getUrl, data, {useLog: true}).then(res => {
        this.confirmOrder = res.data;
        let getUrl2 = '';
        let data2 = {
          is_read: this.agree
        };

        if(this.courseId.includes('series')){
          getUrl2 = '/v1/subject/createOrder';
          data2.subject_id = this.courseId.split('_')[1];
        }else{
          getUrl2 = '/v1/course/createOrder';
          data2.course_id = this.courseId;
          data.source ='2'
        }

        //创建订单
        this.$axios.post(getUrl2, data2, {useLog: true}).then(res => {
          this.payInfo = res.data;
        });
      });
    },
    payBtn(){
      let www = window.location.href.split('/#')[0]; //获取域名
      let return_url = '';

      if(this.courseId.includes('series')){
        return_url = www + '/#/seriesLessonsInfo/'+ this.courseId.split('_')[1];
      }else{
        return_url = www + '/#/recordedInfo/'+ this.courseId;
      }

      this.$axios.post(`/v1/course/payOrder`, {
        order_no: this.payInfo.order_no,
        pay_type: this.payType,
        return_url: encodeURIComponent(return_url)
      }, {useLog: true}).then(res => {
        if(res.code == 1){
          this.ShowTipModal({
            text: res.msg     //提示弹窗内容
          })
        }else if(res.code == 0){
          if(this.getIsWeiXin()){
            res.data.content.return_url = return_url;
            let invoke = Qs.stringify(res.data.content);
            window.location.href = 'https://pay.weixin.kskstudy.com?' + invoke;  //跳转微信内支付调用
          }else{
            this.zhifu = res.data.content;

            if(this.payType == 1){
              this.$nextTick(() => {
                document.forms[0].submit()   //渲染支付宝支付页面
              })
            }
          }
        }else{
          this.$axios.post(`/v1/login/getWeiXinAuthorize`, {
            return_url: www + '/#/confirmOrder/'+ this.confirmOrder.id,
          }, {useLog: true}).then(res => {
            window.location.href = res.data.url;
          })
        }
      });
    },
    //判断是否为微信浏览器
    getIsWeiXin(){
      const ua = navigator.userAgent.toLowerCase();
      const isWeixin = ua.indexOf('micromessenger') != -1;
      if (isWeixin) {
          return true;
      }else{
          return false;
      }
    }
  }
}
</script>
<style scoped lang="scss">
.confirm-order{
  box-sizing: border-box;
  padding-bottom: 98px;
  position: relative;
}
.confirm-content{
  padding: 28px;
}
.order-info{
  padding: 28px;
  box-shadow: 0px 0px 10px 0px rgba(0, 12, 40, 0.14);
  border-radius: 6px;
  .info{
    overflow: hidden;
    margin-bottom: 60px;
  }
  .order-img{
    width: 242px;
    height: 134px;
    border-radius: 6px;
    float: left;
  }
  .right{
    width: 376px;
    height: 134px;
    float: right;
    position: relative;
    .name{
      font-size: 28px;
      color: #333;
      line-height: 38px;
      max-height: 36px;
    }
    .price{
      font-size: 36px;
      color: #CD1B14;
      position: absolute;
      left: 0;
      bottom: 0;
      .unit{
        font-size: 22px;
        
      }
    }
  }
  .info-2{
    display: flex;
    justify-content: space-between;
    font-size: 26px;
    margin-bottom: 28px;
    .title{
      color: #666;
    }
    .text{
      color: #999;
    }
  }
}
.actual-payment{
  font-size: 28px;
  color: #333;
  text-align: right;
  margin-top: 50px;
  .unit{
    font-size: 22px;
    color: #CD1B14;
    margin-left: 10px;
  }
  .price{
    font-size: 36px;
    color: #CD1B14;
  }
}

.pay-type{
  padding: 58px 45px;
  box-shadow: 0px 0px 10px 0px rgba(0, 12, 40, 0.14);
  border-radius: 6px;
  margin-top: 28px;
  .title{
    font-size: 28px;
    color: #666;
  }
}
.pay-s{
  display: block;
  padding-left: 100px;
  height: 38px;
  line-height: 38px;
  position: relative;
  &::before{
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background: url(~@/assets/yuan-grey-ico.png) no-repeat;
    background-size: auto 24px;
    position: absolute;
    left: 0;
    top: 7px;
  }
  &.active::before{
    background: url(~@/assets/yuan-blue-ico.png) no-repeat;
    background-size: auto 24px;
  }
  &.weixin{
    background: url(~@/assets/weixin-logo.png) 50px center no-repeat;
    background-size: auto 38px;
    margin-top: 58px;
  }
  &.zhifubao{
    background: url(~@/assets/zhifubao-logo.png) 50px center no-repeat;
    background-size: auto 38px;
    margin-top: 68px;
  }
}
.sub-btn{
  width: 164px;
  height: 58px;
  line-height: 40px;
  background: #254ED4;
  border-radius: 6px;
  font-size: 28px;
  color: #FFF;
  position: absolute;
  right: 28px;
  bottom: 20px;
}
.zhifu-content{
  display: none;
}
</style>